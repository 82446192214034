<template>
    <div :key="this.$store.state.sidebarComponentKey" class="sidebar">
        <div style="z-index: 998;" class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
        <transition name="slide">
            <div style="z-index: 999;" v-if="isPanelOpen" class="sidebar-panel">
                <div class="menu_header">
                    <button class="menu_header_left" @click="closeSidebarPanel"></button>
                    <div class="menu_header_center">
                      {{ $t('menu.title') }}
                    </div>
                    <router-link to="/profile">
                        <div @click="closeSidebarPanel" class="menu_header_right">
                            <ProfileIcon></ProfileIcon>
                        </div>
                    </router-link>
                </div>
                <div v-if="!betgame" class="menu_content">
                    <router-link to="/mybetgames">
                        <div @click="closeSidebarPanel" class="menu_item menu_item--highlighted">
                            <img v-if="betgame && isValidIcon(betgame.icon)" v-bind:src="buildIconPath(betgame.icon, 300)">
                            <img v-else src="../../assets/landingpage/logo-teamtip.svg">
                            {{ $t('menu.no_betgame_selected') }}
                        </div>
                    </router-link>
                    <div class="menu_item menu_item--actions">
                        <router-link to="/mybetgames" >
                            <div class="menu_item--actions-item" v-bind:class="{ indicator : pendingTipsCount > 0 }" @click="closeSidebarPanel">
                                <img src="../../assets/icons/ic_16_switch.svg">
                                {{ $t('menu.switch_betgame') }}
                                <div v-if="pendingTipsCount > 0" class="indicator">
                                    {{ pendingTipsCount }}
                                </div>
                            </div>
                        </router-link>
                        <router-link to="/createuserbetgame">
                            <div class="menu_item--actions-item" @click="closeSidebarPanel">
                                <img src="../../assets/icons/ic_16_add.svg">
                                {{ $t('menu.create_betgame') }}
                            </div>
                        </router-link>
                    </div>
                </div>
                <div v-else-if="betgame" class="menu_content">
                    <router-link to="/mybetgames">
                        <div v-if="betgame.id === 55167 || betgame.id === 57003" @click="closeSidebarPanel" class="menu_item menu_item--highlighted">
                          <img src="../../assets/images/misc/57003.png" srcset="../../assets/images/misc/57003@2x.png 2x" style="width: 100%; height: 100%; margin: 0 -16px; position: absolute; top: 0; object-fit: cover">
                        </div>
                        <div v-else-if="betgame.id === 53773 || betgame.id === 59320" @click="closeSidebarPanel" class="menu_item menu_item--highlighted">
                          <img src="../../assets/images/misc/beat_the_coach.png" style="width: 100%; height: 100%; margin: 0 -16px; position: absolute; top: 0; object-fit: cover">
                        </div>
                        <div v-else @click="closeSidebarPanel" class="menu_item menu_item--highlighted">
                          <img v-if="betgame && isValidIcon(betgame.icon)" v-bind:src="buildIconPath(betgame.icon, 300)">
                          <img v-else src="../../assets/landingpage/logo-teamtip.svg">
                          <div class="menu_item__text">
                            {{ betgame.name }}
                            <div v-if="isBattle()" class="battle-badge">
                              Battle
                            </div>
                            <div v-else-if="isTeamsBetgame()" class="battle-badge">
                              Team
                            </div>
                          </div>
                        </div>
                    </router-link>
                    <div class="menu_item menu_item--actions">
                        <router-link to="/mybetgames" >
                            <div class="menu_item--actions-item" v-bind:class="{ indicator : pendingTipsCount > 0 }" @click="closeSidebarPanel">
                                <img src="../../assets/icons/ic_16_switch.svg">
                                {{ $t('menu.switch_betgame') }}
                                <div v-if="pendingTipsCount > 0" class="indicator">
                                    {{ pendingTipsCount }}
                                </div>
                            </div>
                        </router-link>
                        <!--
                        <router-link v-if="this.isSPMUser()" to="/erstellen">
                          <div class="menu_item--actions-item" @click="closeSidebarPanel">
                            <img src="../../assets/icons/ic_16_add.svg">
                            {{ $t('menu.create_betgame') }}
                          </div>
                        </router-link>
                        -->
                        <router-link to="/createuserbetgame">
                            <div class="menu_item--actions-item" @click="closeSidebarPanel">
                                <img src="../../assets/icons/ic_16_add.svg">
                                {{ $t('menu.create_betgame') }}
                            </div>
                        </router-link>
                    </div>
                    <router-link to="/feed/dashboard" class="betgame_menu_item full-width">
                      <div @click="closeSidebarPanel" class="menu_item">
                        <span>{{ $t('menu.dashboard') }}</span>
                        <span v-if="unreadCount > 0" class="badge">{{ unreadCount }}</span>
                      </div>
                    </router-link>
                    <router-link v-if="this.hasSchedule()" to="/bet" class="betgame_menu_item">
                        <div @click="closeSidebarPanel" class="menu_item">
                            <span>{{ $t('menu.bet') }}</span>
                            <span v-if="pendingTipsCountCurrentBetgame > 0" class="badge">{{ pendingTipsCountCurrentBetgame }}</span>
                        </div>
                    </router-link>
                    <router-link v-if="this.hasSchedule()" to="/rankingsbymatchday" class="betgame_menu_item">
                        <div @click="closeSidebarPanel" class="menu_item">
                            <span>{{ $t('menu.matchday_ranking') }}</span>
                        </div>
                    </router-link>
                    <router-link to="/rankings" class="betgame_menu_item">
                        <div @click="closeSidebarPanel" class="menu_item">
                            <span>{{ $t('menu.total_ranking') }}</span>
                        </div>
                    </router-link>
                    <router-link v-if="this.hasSchedule() && questions && questions.length > 0" to="/specialquestions" class="betgame_menu_item full-width">
                        <div @click="closeSidebarPanel" class="menu_item">
                            <span>{{ $t('menu.special_questions') }}</span>
                            <span v-if="pendingAnswersCount > 0" class="badge">{{ pendingAnswersCount }}</span>
                        </div>
                    </router-link>
                    <router-link v-if="this.$store.state.betgame && this.$store.state.betgame.chat && this.$store.state.betgame.chat !== ''" to="/feed/chat" class="betgame_menu_item full-width">
                      <div @click="closeSidebarPanel" class="menu_item">
                        <span>Chat</span>
                        <span v-if="unreadCount > 0" class="badge">{{ unreadCount }}</span>
                      </div>
                    </router-link>
                    <router-link v-if="this.hasSchedule() && betgame.competition && betgame.competition.type === 'LEAGUE'" to="/table" class="betgame_menu_item full-width">
                        <div @click="closeSidebarPanel" class="menu_item">
                            <span>{{ $t('menu.league_table') }}</span>
                        </div>
                    </router-link>
                    <!--
                    <router-link v-if="this.isSPMUser() && this.hasSchedule() && betgame.competition && betgame.competition.type === 'LEAGUE'" to="/tableByBets" class="betgame_menu_item full-width">
                      <div @click="closeSidebarPanel" class="menu_item">
                        <span>{{ $t('menu.league_table_by_bets') }}</span>
                      </div>
                    </router-link>
                    -->
                    <router-link v-else-if="this.hasSchedule() && betgame.competition && betgame.competition.type === 'TOURNAMENT' && betgame.competition.id !== 33" to="/tournament" class="betgame_menu_item full-width">
                        <div @click="closeSidebarPanel" class="menu_item">
                            <span>{{ $t('menu.tournament_groups_schedule') }}</span>
                        </div>
                    </router-link>
                    <router-link to="/rules" class="betgame_menu_item full-width">
                        <div @click="closeSidebarPanel" class="menu_item">
                            <span>{{ $t('menu.rules') }}</span>
                        </div>
                    </router-link>
                    <router-link v-if="this.isAdminUser()" to="/audio" class="betgame_menu_item full-width">
                      <div @click="closeSidebarPanel" class="menu_item">
                        <span>Audio</span>
                      </div>
                    </router-link>
                    <router-link v-if="this.isBetgameAdmin()" to="/betgameedit" class="betgame_menu_item full-width">
                        <div @click="closeSidebarPanel" class="menu_item">
                            <span>{{ $t('menu.settings') }}</span>
                        </div>
                    </router-link>
                    <router-link v-else to="/userbetgamesettings" class="betgame_menu_item full-width">
                        <div @click="closeSidebarPanel" class="menu_item">
                            <span>{{ $t('menu.settings') }}</span>
                        </div>
                    </router-link>
                    <router-link v-if="this.isBetgameAdmin()" to="/sharebetgame" class="betgame_menu_item full-width">
                      <div @click="closeSidebarPanel" class="menu_item">
                        <span>{{ $t('betgame_settings.share_button_text') }}</span>
                      </div>
                    </router-link>
                    <div v-if="this.isBetgameAdmin() && this.$store.state.betgame.type === 1 && 
                        this.$store.state.betgame.season.id === 14 && this.$store.state.betgame.competition.id !== 1110 && this.$store.state.betgame.competition.id !== 36 && this.$store.state.betgame.competition.id !== 139" @click="closeSidebarPanel" class="powered-by-teamtip" style="margin-top: 16px;">
                      <button v-on:click="copyBetgame"><img class="button-icon button-icon--left" src="../../assets/icons/ic_12_plus_white.svg">{{ $t('betgame_settings.betgame_copy') }}</button>
                    </div>
                    <div v-else-if="this.$store.state.betgame && this.$store.state.betgame.type === 1 && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1110" @click="closeSidebarPanel" class="powered-by-teamtip" style="margin-top: 16px;">
                      <button v-on:click="createBetgame"><img class="button-icon button-icon--left" src="../../assets/icons/ic_12_plus_white.svg">{{ $t('dashboard.button_start_womman_league_betgame') }}</button>
                    </div>
                    <div v-else-if="this.isBetgameAdmin()" @click="closeSidebarPanel" class="powered-by-teamtip" style="margin-top: 16px;">
                        <button v-on:click="createBetgame"><img class="button-icon button-icon--left" src="../../assets/icons/ic_12_plus_white.svg">{{ $t('dashboard.button_start_betgame') }}</button>
                    </div>
                    <div v-else-if="!this.isBetgameAdmin()" @click="closeSidebarPanel" class="powered-by-teamtip" style="margin-top: 16px;">
                        <button v-on:click="createBetgame"><img class="button-icon button-icon--left" src="../../assets/icons/ic_12_plus_white.svg">{{ $t('dashboard.button_start_own_betgame') }}</button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
  import { menustore, menumutations } from '../../vuex/store.js'
  import ProfileIcon from '../icons/ProfileIcon'
  
  export default {
    methods: {
      closeSidebarPanel: menumutations.toggleNav,
      createBetgame() {
        this.$gtag.event('EigeneTipprunde', {
          'event_category': 'createBetgame',
          'event_label': 'teamtip.sidebar',
          'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
          'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
          'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
          'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
        })
        this.$router.push('/createuserbetgame')
      },
      copyBetgame() {
        this.$gtag.event('TipprundeKopieren', {
            'event_category': 'copyBetgame',
            'event_label': 'teamtip.sidebar',
            'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
            'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
            'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
            'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
        })
        this.$router.push({ name: 'betgamecopy', params: { betgame: this.$store.state.betgame, action: 'copy' } })
      }
    },
    components : {
        ProfileIcon
    },
    computed: {
      unreadCount() {
        return this.$store.state.unreadCount
      },
      pendingAnswersCount() {
        if(this.$store.state.questions) {
          const questions = this.$store.state.questions.filter(q => parseInt(q.fk_betgame) === parseInt(this.$store.state.betgameId))
          var result = 0
          for (var i = 0; i < questions.length; i++) {
            const question = questions[i]
            const editableUntil = this.parseMatchdate(question.editable_until)
            if(editableUntil > Date.now()) {
              const answers = this.$store.state.answers.filter(a => parseInt(a.fk_question) === parseInt(question.id))
              if(answers.length !== question.answer_count) {
                result += question.answer_count
              }
            }
          }
          return result
        }
        return 0
      },
      answerCount() {
        if(!this.$store.state.answers || this.$store.state.updateAnswerCount) {
          this.$store.dispatch('updateAnswerCount', false).then(function (response) {
          })
          this.$store.dispatch('fetchUserAnswers', []).then(function (response) {
            return this.$store.state.answers ? this.$store.state.answers.length : 0
          })
        }
        return this.$store.state.answers ? this.$store.state.answers.length : 0
      },
      pendingTipsCountCurrentBetgame() {
        if(this.$store.state.missing_bets !== undefined) {
          const mb = this.$store.state.missing_bets
          var result = 0
          for (var i = 0; i < mb.length; i++) {
            const item = mb[i]
            if (item.matchcount > item.betcount && item.id === this.$store.state.betgame.id) {
              result += item.matchcount - item.betcount
            }
          }
          return result
        } else {
          this.$store.dispatch('fetchUserBetgames', this.$store.state.user.id)
          return 0
        }
      },
      pendingTipsCount() {
        if(this.$store.state.missing_bets !== undefined) {
          const mb = this.$store.state.missing_bets
          var result = 0
          for (var i = 0; i < mb.length; i++) {
            const item = mb[i]
            if (item.matchcount > item.betcount && item.id !== this.$store.state.betgame.id) {
              result += item.matchcount - item.betcount
            }
          }
          return result
        } else {
          this.$store.dispatch('fetchUserBetgames', this.$store.state.user.id)
          return 0
        }
      },
      questions() {
        return this.$store.state.questions.filter(q => parseInt(q.fk_betgame) === parseInt(this.$store.state.betgameId))
      },
      betgame() {
        return this.$store.state.betgame
      },
      isPanelOpen() {
        return menustore.isNavOpen
      }
    }
  }
</script>
<style lang="scss" scoped>
    .slide-enter-active,
    .slide-leave-active
    {
        transition: transform 0.2s ease;
    }
    
    .slide-enter,
    .slide-leave-to {
        transform: translateX(-100%);
        transition: all 150ms ease-in 0s
    }
    
    .sidebar-backdrop {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
        background: rgba(0,0,0,.2);
        z-index: 998;
    }
    
    .sidebar-panel {
        box-shadow: 0 0 24px 0 rgba(0,0,0,0.10);
        overflow-y: auto;
        overflow-x: hidden;
        background-color: $colorWhite;
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        z-index: 999;
        padding: 0rem 0px 2rem 0px;
        width: 80%;
        max-width: 314px;
    }
    
    .menu_header {
        display: flex;
        align-items: center;
        width: 100%;

        &_left {
            width: 48px;
            height: 64px;
            background: url('../../assets/icons/ic_16_close.svg');
            background-position: center center;
            background-repeat: no-repeat;

            &:hover {
                box-shadow: none;
            }
        }

        &_center {
            margin: 0px auto;
        }

        &_right {
            width: 24px;
            height: 24px;
            padding: 20px 16px;
        }
    }
    .menu_close {
        width: 64px;
        height: 64px;
    }
    .menu_content {
        padding: 0px 16px 16px 16px;
        padding-bottom: 150px;

        a {
            text-decoration: none;
        }
    }
    
    .menu_close_button {
        margin: 5px;
        width: 20px;
        height: 20px;
        float: right;
    }

    .betgame_menu_item {
        text-decoration: none;

        &:last-child {
            .menu_item {
                box-shadow: none;
            }
        }
    }

    a {
        &:not(:last-of-type) {
            .menu_item {
                box-shadow: 0 1px 0 0 rgba($colorGreyLight, .08);
            }
        }
    }

    .menu_item {
        position: relative;
        display: flex;
        align-items: center;
        position: relative;
        font-size: 14px;
        font-weight: 500;
        color: $colorGreyLight;
        padding: 17px 0 14px;

        &:last-child {
            box-shadow: none;
        }

        .badge {
            padding: 3.5px 2px 2.5px;
            box-sizing: border-box;
            text-align: center;
            border-radius: 10px;
            font-size: 11px;
            line-height: 14px;
            font-weight: 500;
            color: $colorWhite;
            min-width: 20px;
            width: auto;
            background: $colorNegative;
            margin: -2px 20px -1px auto;
        }

        &:before {
            content: '';
            position: absolute;
            top: 1px;
            left: 0;
            width: calc(100% + 32px);
            margin-left: -16px;
            height: calc(100% - 1px);
        }

        &:hover {
            &:before {
                background: rgba($colorGreyDark, .04);
            }
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 16px;
            width: 16px;
            height: 16px;
            background: url('../../assets/icons/ic_16_drilldown.svg');
        }

        &__text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &--highlighted {
            background: var(--color-primary);
            margin: 0 -16px;
            padding: 12px 16px;
            min-height: 64px;
            box-sizing: border-box;
            color: $colorWhite;

            &:hover {
                box-shadow:inset 0 0 0 99999px rgba(0,0,0,.04);
            }

            &:after {
                display:none;
            }

            img {
                width: 24px;
                height: 24px;
                margin-right: 12px;
                border-radius: 3px;
                object-fit: cover;
            }

            .battle-badge {
                display: inline-flex;
                background: $colorWhite;
                padding: 2px 3px;
                color: var(--color-primary);
                font-size: 9px;
                font-weight: 600;
                letter-spacing: -0.25px;
                text-transform: uppercase;
                text-decoration: none;
                border-radius: 1.5px;
                margin-top: 4px;
            }
        }

        &--actions {
            background: var(--color-primary);
            margin: 0 -16px;
            padding: 0 16px;
            margin-bottom: 8px;

            &:after {
                display: none;
            }

            a {
                position: relative;
                display: flex;
                flex: 1;
                text-decoration: none;

                &:first-child {
                    &:after {
                        content: '';
                        position: absolute;
                        background: rgba($colorWhite,.16);
                        right: 0;
                        top: 50%;
                        height: 20px;
                        width: 1px;
                        transform: translateY(-50%);
                    }
                }
            }

            &-item {
                padding: 12px 0;
                box-shadow: 0 -1px 0 0 rgba($colorWhite,.16);
                display: flex;
                flex: 1;
                justify-content: center;
                text-decoration: none;
                color: $colorWhite;

                img {
                    margin-right: 8px;
                }

                .indicator {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $colorNegative;
                    width: 16px;
                    height: 16px;
                    border-radius: 8px;
                    box-shadow: 0 0 0 2px var(--color-primary);
                    color: $colorWhite;
                    font-size: 11px;
                    margin-left: -3px;
                    margin-top: -7px;
                }
            }
        }
    }
</style>

<template>
  <div class="input-container" style="margin-bottom: 50px;">
    <div class="input-header">
      'Tippübersicht' anpassen
    </div>
    <div class="input-infotext">
      {{ $t('profile.change_rankings_page_size') }}
    </div>
    <select @change="changed($event)" v-model="value" name="pagesize" id="pagesize" style="width: 100%; margin-top: 0px; margin-bottom: 20px;">
      <option value="5">5</option>
      <option value="10">10</option>
      <option value="15">15</option>
      <option value="20">20</option>
      <option value="25">25</option>
      <option value="30">30</option>
      <option value="50">50</option>
      <option value="100">100</option>
      <option value="150">150</option>
      <option value="200">200</option>
      <option value="250">250</option>
    </select>
  </div>
</template>

<script>
import { RANKINGS_PAGE_SIZE } from '@/main'

export default {
  name: 'RankingsPerPage',
  components: {},
  data () {
    return {
      value: parseInt(this.getBGValue('spmrps') ? this.getBGValue('spmrps') : RANKINGS_PAGE_SIZE),
    }
  },
  beforeCreate () {
  },
  created () {
  },
  mounted () {
  },
  computed: {},
  methods: {
    changed (event) {
      this.value = event.target.value
      this.setBGValue('spmrps', parseInt(event.target.value))
    }
  }
}
</script>

<style lang="scss">

</style>


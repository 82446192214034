<template>
  <div class="betgameeditusersettings" id="betgameeditusersettings">

    <NavigationBar>
      <template v-slot:left>
        <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
      </template>
      <template v-slot:center>
        {{ $t('betgame_settings.user_settings') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>
    
    <GenerateBets v-if="this.isSPMUser()"></GenerateBets>
    <RankingsPerPage style="margin-top: 16px;"></RankingsPerPage>
    
  </div>
</template>

<script>

import NavigationBar from './../components/NavigationBar'
import GenerateBets from '@/components/settings/GenerateBets.vue'
import RankingsPerPage from '@/components/settings/RankingsPerPage.vue'

export default {
  name: 'BetgameEditUserSettings',
  components: { RankingsPerPage, GenerateBets, NavigationBar },
  data () {
    return {
      betgame: null
    }
  },
  created () {
    this.betgame = this.$route.params.betgame
  },
  mounted () {
    if (this.betgame === null || this.betgame === undefined) {
      this.goBack()
    }
  },
  computed: {},
  methods: {
    goBack () {
      this.$router.push('/betgameedit')
    }
  }
}
</script>

<style lang="scss" scoped>
.betgameeditusersettings {

  form {
    padding-top: 16px;
  }

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
    margin-bottom: 26px;
  }
}
</style>

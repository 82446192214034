<template>
  <div class="loadingmodal" style="background-color: rgba(255, 255, 255, 0.7); backdrop-filter: blur(4px);">
    <img src="@/assets/icons/ic_spinner.svg">
    <div class="loadingmodal__label">
      Loading...
    </div>
    <div class="loadingmodal__sublabel">
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingModal',
  components: {},
  data () {
    return {}
  },
  beforeCreate () {
  },
  created () {
  },
  mounted () {
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
.loadingmodal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $colorWhite;
  color: var(--color-primary);

  img {
    margin-bottom: 48px;
    animation: spinner 1s infinite;
    width: 64px;
  }

  @keyframes spinner {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &__label {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 3px;
  }

  &__sublabel {
    font-size: 14px;
  }
}
</style>

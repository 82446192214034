/* eslint-disable */
<template>
  <div :key="componentKey" class="betgame_edit" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
    
    <keep-alive>
      <NavigationBar id="betgameEditNavigationBarId" ref="betgameEditNavigationBarRef">
        <template v-slot:center>
          {{ $t('menu.settings') }}
        </template>
      </NavigationBar>
    </keep-alive>
      
    <div v-if="this.isFutureSeason()" class="notification">
      <p>{{ $t('betgame_settings.betgame_edit_copy_hint') }}</p>
    </div>
      
    <div class="notification">
      <h1>{{ $t('betgame_settings.share_betgame_title') }}</h1>
      <p>{{ $t('betgame_settings.share_betgame_text') }}</p>
      <button v-on:click="shareButtonClicked"><img class="button-icon button-icon--left" src="../assets/icons/ic_12_plus_white.svg">{{ $t('betgame_settings.share_button_text') }}</button>
    </div>
    
    <!--
    <div class="input-container">
      <div class="input-header">
        Allgemeine Infos
      </div>
      <p>
        <label for="betgame_id">ID</label>
        <input name="betgame_id" id="betgame_id" style="margin-left: 20px; width: 20%;" v-model="betgame.id" readonly>
      </p>
      <div class="input-header">
        Tipprunde Name
      </div>
      <div class="input-element">
        <input @blur="handleNameBlur" v-model="betgame.name" style="width: 100%;">
      </div>
      <div v-if="betgame.competition">
        <div class="input-header">
          Wettbewerb
        </div>
        <div class="input-element">
          <input readonly v-model="betgame.competition.name" style="width: 100%;">
        </div>
      </div>
      <div class="input-header">
        Tipprunde Komntakt
      </div>
      <div class="input-element">
        <input @blur="handleEmailBlur" v-model="betgame.email" style="width: 100%;">
      </div>
      <div>
        <input @blur="handleIsPublic3Blur" type="checkbox" id="private" v-model="betgame.is_public">
        <label for="private">Öffentlich</label>
      </div>
      <div>
        <input type="checkbox" id="hideads" v-model="betgame.hide_ads">
        <label for="hideads">Ads</label>
      </div>
    </div>
  
    <div class="input-container">
      <div class="input-header">
        Wie viele Puknte erhalten Teilnehmende für einen richtigen Tipp.
      </div>
      <div>
        <p>
          <label for="points1">Exaktes Ergebnis</label>
          <input @blur="handlePoints1Blur" id="points1" style="margin-left: 20px; width: 20%;" v-model="betgame.points_bet_result">
        </p>
        <p>
          <label for="points2">Tordifferenz</label>
          <input @blur="handlePoints2Blur" id="points2" style="margin-left: 20px; width: 20%;" v-model="betgame.points_bet_goaldiff">
        </p>
        <p>
          <label for="points3">Ergebnis</label>
          <input @blur="handlePoints3Blur" id="points3" style="margin-left: 20px; width: 20%;" v-model="betgame.points_bet_tendency">
        </p>
      </div>
    </div>
    -->
    
    <div class="table-view">
      <div class="table-view-header">
        {{ $t('betgame_settings.betgame_basic_info') }} ({{ $t('betgame_settings.betgame_id') }}: {{ betgame.id }})
      </div>
      <router-link :to="{ name: 'betgameeditname', params: { betgame: betgame }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.betgame_name') }}</div>
          <div v-if="betgame" class="table-view-cell-value truncate">{{ betgame.name }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link :to="{ name: 'betgameeditdescription', params: { betgame: betgame }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.betgame_description') }}</div>
          <div class="table-view-cell-value truncate">{{ betgame.description }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link v-if="this.$store.state.user && this.$store.state.user.id === 93" :to="{ name: 'betgameeditrules', params: { betgame: betgame }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.betgame_rules') }}</div>
          <div class="table-view-cell-value truncate">{{ betgame.rules }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link :to="{ name: 'betgameediticon', params: { betgame: betgame }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.betgame_logo') }}</div>
          <img v-if="isValidIcon(betgame.icon)" v-bind:src="buildIconPath(betgame.icon, 300)">
          <img v-else src="../assets/icons/ic_24_logo.svg">
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link v-if="betgame.is_public" :to="{ name: 'betgameeditpublic', params: { betgame: betgame }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.betgame_is_public') }}</div>
          <div class="table-view-cell-value">{{ betgame.is_public ? $t('yes') : $t('no') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <div v-if="this.isBattle() || this.isTeamsBetgame()" class="table-view-cell">
        <div class="table-view-cell-label">{{ $t('betgame_settings.betgame_type') }}</div>
        <div v-if="betgame && betgame.type" class="table-view-cell-value">{{ betgameTypeName }}</div>
        <!-- <div class="table-view-cell-drilldown"></div> -->
      </div>
      <div v-if="!this.isBattle() && !this.isTeamsBetgame()" class="table-view-cell">
        <div class="table-view-cell-label">{{ $t('betgame_settings.betgame_selected_competition') }}</div>
        <div  v-if="betgame && betgame.competition" class="table-view-cell-value">{{ betgame.competition.name }}</div>
        <!-- <div class="table-view-cell-drilldown"></div> -->
      </div>
      <div v-if="this.isTeamsBetgame()" class="table-view-cell">
        <div class="table-view-cell-label">{{ $t('create_betgame.select_type_teams') }}</div>
        <div class="table-view-cell-value">{{ teamNames }}</div>
      </div>
      <div class="table-view-cell">
        <div class="table-view-cell-label">{{ $t('betgame_settings.betgame_selected_season') }}</div>
        <div v-if="betgame && betgame.season" class="table-view-cell-value">{{ betgame.season.name }}</div>
        <!-- <div class="table-view-cell-drilldown"></div> -->
      </div>
      <router-link v-if="betgame.competition.id !== 533 || betmode === 1" :to="{ name: 'betgameeditbetmode', params: { betgame: betgame }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.bet_mode') }}</div>
          <div class="table-view-cell-value">{{ (!betmode || betmode === 1) ? $t('betgame_settings.bet_mode_result') : $t('betgame_settings.bet_mode_toto') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link v-if="this.isBattle() || this.isTournament() || isTeamsBetgame()" :to="{ name: 'betgameeditresultmode', params: { betgame: betgame }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.result_mode') }}</div>
          <div v-if="resultmode === 3" class="table-view-cell-value">{{ $t('betgame_settings.result_mode_90m') }}</div>
          <div v-else-if="resultmode === 2" class="table-view-cell-value">{{ $t('betgame_settings.result_mode_120m') }}</div>
          <div v-else-if="!resultmode || resultmode === 1" class="table-view-cell-value">{{ $t('betgame_settings.result_mode_total') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link :to="{ name: 'betgameeditpoints', params: { betgame: betgame }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.betgame_evaluation_method') }}</div>
          <div v-if="betmode === 1" v-bind:class="{ betgame_points_warning: showBetgamePointsWarning }" class="table-view-cell-value">{{ betgame.points_bet_result }}/{{ betgame.points_bet_goaldiff }}/{{ betgame.points_bet_tendency }}</div>
          <div v-else-if="betmode === 2" class="table-view-cell-value">{{ betgame.points_bet_result }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link v-if="!this.isBattle() && !this.isTournament()" :to="{ name: 'betgameeditsortmode', params: { betgame: betgame }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.tie_ranking_rules') }}</div>
          <div class="table-view-cell-value"></div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link v-if="this.hasSchedule()" :to="{ name: 'betgameeditspecialquestions', params: { betgame: betgame }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.special_questions') }}</div>
          <div class="table-view-cell-value" v-bind:class="specialQuestionSolutionMissing ? 'solution_missing' : ''">{{ questionCount }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link :to="{ name: 'betgameeditchatbot', params: { betgame: betgame }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">Chat</div>
          <div class="table-view-cell-value"></div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link :to="{ name: 'betgameeditusers', params: { betgame: betgame }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.betgame_participants') }}</div>
          <div class="table-view-cell-value">{{ userCount }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <div v-if="this.isBattle()" class="table-view-cell">
        <div class="table-view-cell-label">{{ $t('betgame_settings.battle_match_count') }}</div>
        <div class="table-view-cell-value">{{ battleMatchCount }}</div>
        <!-- <div class="table-view-cell-drilldown"></div> -->
      </div>
      <!--
      <div v-if="this.isBattle() && this.isSPMUser() && betgame.fk_user === currentUser" v-on:click="removeMatchesClicked()" class="table-view-cell">
        <div class="table-view-cell-label">Verlegte Spiele</div>
        <div class="table-view-cell-value">{{ battleMatchStatusCount }}</div>
      </div>
      -->
      <div class="table-view-cell">
        <div class="table-view-cell-label">{{ $t('betgame_settings.betgame_placed_bets') }}</div>
        <div class="table-view-cell-value">{{ betCount }}</div>
        <!-- <div class="table-view-cell-drilldown"></div> -->
      </div>
      <router-link v-if="betgame.fk_user === currentUser" :to="{ name: 'betgameeditusersettings', params: { betgame: betgame }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.user_defined') }}</div>
          <div class="table-view-cell-value"></div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
    </div>
    
    <div class="table-view">
      <div class="table-view-header">
        {{ $t('betgame_settings.betgame_admin_tools') }}
      </div>
      <!--
      <router-link to="/" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">Benachrichtigungen</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      -->
      <router-link v-if="betgame.fk_user === currentUser":to="{ name: 'invitationlink', params: { betgame: betgame, action: 'invitationlink' }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.renew_invitation_link') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link v-if="betgame.fk_user === currentUser && this.$store.state.betgame.type === 1 && 
        this.$store.state.betgame.season.id === 14 && this.$store.state.betgame.competition.id !== 1110 && this.$store.state.betgame.competition.id !== 36 && this.$store.state.betgame.competition.id !== 139" :to="{ name: 'betgamecopy', params: { betgame: betgame, action: 'copy' }}" class="router_link">
        <a href="" class="table-view-cell">
            <div class="table-view-cell-label">{{ $t('betgame_settings.betgame_copy') }}</div>
            <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link v-if="betgame.fk_user === currentUser":to="{ name: 'betgamereset', params: { betgame: betgame, action: 'reset' }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.betgame_reset') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link v-if="betgame.fk_user === currentUser":to="{ name: 'betgamedelete', params: { betgame: betgame, action: 'delete' }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.betgame_delete') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link v-if="betgame.fk_user === currentUser":to="{ name: 'betgamedelete', params: { betgame: betgame, action: 'transfer' }}" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('betgame_settings.betgame_transfer') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
    </div>
    
    <div style="padding-top: 30px;"></div>
  </div>
</template>

<script>
  import NavigationBar from './../components/NavigationBar'
  import Vue from 'vue'
  import { RESULT_MODE_TOTAL } from '@/main'
  export default {
    name: 'BetgameEdit',
    components: { NavigationBar },
    data () {
      return {
        componentKey: 0
      }
    },
    beforeCreate() {
      Vue.$log.debug('BetgameEdit beforeCreate ')
    },
    created () {
      Vue.$log.debug('BetgameEdit created')
      if(!this.isBetgameAdmin()) {
        this.$router.push({ name: 'dashboard' })
      }
    },
    mounted() {
      Vue.$log.debug('BetgameEdit mounted')
      //this.$store.dispatch('fetchBetgame', this.$store.state.betgameId).then(() => {})
      this.$store.dispatch('fetchQuestions', []).then(function (response) {
      })
      this.componentKey += 1
    },
    computed: {
      teamNames() {
        var names = ''
        const temp = this.$store.state.betgameTeams ? this.$store.state.betgameTeams : []
        for(var i=0; i<temp.length; i++) {
          const team = this.$store.state.teams.find(t => t.id === temp[i].fk_team)
          if(team) {
            names += team.shortname + ', '
          }
        }
        return names.length > 1 ? names.substring(0, names.length - 2) : names
      },
      betgameTypeName() {
        if(this.isTeamsBetgame()) {
          return this.$i18n.t('create_betgame.select_type_teams')
        } else if(this.isBattle()) {
          return this.$i18n.t('create_betgame.select_type_battle')
        }
        return this.$i18n.t('create_betgame.select_type_betgame')
      },   
      specialQuestionSolutionMissing() {
        const quests = this.$store.state.questions.filter(q => parseInt(q.fk_betgame) === parseInt(this.$store.state.betgameId))
        if(!quests || quests.length === 0) {
          return false
        }
        var result = false
        for(var i=0; i<quests.length; i++) {
          const eu = this.parseMatchdate(quests[i].editable_until)
          if (eu < Date.now()) {
            const solutions = this.$store.state.solutions.filter(s => s.fk_question === quests[i].id)
            result = !solutions || solutions.length < quests[i].answer_count
            if (result) {
              return true
            }
          }
        }
        return result
      },
      showBetgamePointsWarning() {
        if(this.$store.state.betgame && this.$store.state.betgame.bet_mode === 1) {
          if(this.$store.state.betgame.points_bet_goaldiff > this.$store.state.betgame.points_bet_result || 
              this.$store.state.betgame.points_bet_tendency > this.$store.state.betgame.points_bet_result || 
              this.$store.state.betgame.points_bet_tendency > this.$store.state.betgame.points_bet_goaldiff) {
            return true
          }
        }
        return false
      },
      betgame() {
        return this.$store.state.betgame
      },
      betmode() {
        return (this.$store.state.betgame && this.$store.state.betgame.bet_mode && parseInt(this.$store.state.betgame.bet_mode) > 0) ?
            parseInt(this.$store.state.betgame.bet_mode) : 1
      },
      resultmode() {
        return (this.$store.state.betgame && this.$store.state.betgame.result_mode && parseInt(this.$store.state.betgame.result_mode) > 0) ?
            parseInt(this.$store.state.betgame.result_mode) : RESULT_MODE_TOTAL
      },
      questionCount() {
        const temp = this.$store.state.questions.filter(q => parseInt(q.fk_betgame) === parseInt(this.$store.state.betgameId))
        return temp.length
      },
      userCount() {
        //if(this.$store.state.userCount < 0) {
          this.$store.dispatch('fetchUserCount', 'exact').then(response => {
            this.componentKey += 1
          })
        //}
        return this.$store.state.userCount
      },
      betCount() {
        //if(this.$store.state.betCount < 0) {
          this.$store.dispatch('fetchBetCount', 'exact').then(response => {
            this.componentKey += 1
          })
        //}
        return this.$store.state.betCount
      },
      currentUser() {
        return this.$store.state.user.id
      },
      battleMatchCount() {
        if(this.$store.state.matches) {
          return this.$store.state.matches.length
        }
        return 0
      },
      battleMatchStatusCount() {
        if(this.$store.state.matches) {
          const newArray = this.$store.state.matches.filter(function (el) {
            return el.status && el.status.trim().length > 0;
          });
          return newArray.length
        }
        return 0
      },
      title() {
        return this.betgame.name || 'Tipprunde'
      },
      share_betgame_link() {
        return this.$store.state.apiUrl + '/join/' + this.$store.state.betgame.id + '/' + this.$store.state.betgame.invitation_code
      }
    },
    methods: {
      removeMatchesClicked() {
        const statusMatches = this.$store.state.matches.filter(function (el) {
          return el.status && el.status.trim().length > 0;
        });
        if(statusMatches.length === 0) {
          Vue.$log.debug('removeMatchesClicked no matches found ' + this.$store.state.betgame.id)
          return
        }
        var matchIds = ''
        for (var i = 0; i < statusMatches.length; i++) {
          matchIds += statusMatches[i].id + ','
        }
        matchIds += '0'
        Vue.$log.debug('deleteBetgameMatches ' + this.$store.state.betgame.id + ' --- ' + matchIds)
        this.$store.dispatch('deleteBetgameMatches', {
          betgame: this.$store.state.betgame.id,
          matches: matchIds
        }).then(response => {
          Vue.$log.debug('removeMatchesClicked deleteBetgameMatches ok')
          this.$store.dispatch('fetchSchedule', 0)
        }, error => {
          Vue.$log.debug('removeMatchesClicked deleteBetgameMatches error ' + error.toString())
        })
      },
      shareButtonClicked() {
        this.$router.push({ name: 'sharebetgame'})
        /*
        if (navigator.share) {
          navigator.share({
            title: this.$t('betgame_settings.share_invitation_text'),
            url: this.share_betgame_link
          }).then(() => {
            // Vue.$log.debug('Thanks for sharing!');
          })
          .catch(console.error);
        } else {
          // fallback
          this.$router.push({ name: 'sharebetgame'})
        }
        */
      },
      handlePoints1Blur() {
        this.$store.dispatch('setBetgame', this.betgame)
        this.updateBetgame({points_bet_result: this.betgame.points_bet_result})
      },
      handlePoints2Blur() {
        this.$store.dispatch('setBetgame', this.betgame)
        this.updateBetgame({points_bet_goaldiff: this.betgame.points_bet_goaldiff})
      },
      handlePoints3Blur() {
        this.$store.dispatch('setBetgame', this.betgame)
        this.updateBetgame({points_bet_tendency: this.betgame.points_bet_tendency})
      },
      handleDescriptionBlur() {
        this.$store.dispatch('setBetgame', this.betgame)
        this.updateBetgame({description: this.betgame.description})
      },
      handleNameBlur() {
        this.$store.dispatch('setBetgame', this.betgame)
        this.updateBetgame({name: this.betgame.name})
      },
      handleRulesBlur() {
        this.$store.dispatch('setBetgame', this.betgame)
        this.updateBetgame({rules: this.betgame.rules})
      },
      handleEmailBlur() {
        this.$store.dispatch('setBetgame', this.betgame)
        this.updateBetgame({email: this.betgame.email})
      },
      handleIsPublic3Blur() {
        this.$store.dispatch('setBetgame', this.betgame)
        this.updateBetgame({is_public: this.betgame.is_public})
      },
      /*
      descriptionInput({ type, target }) {
        // @input="descriptionInput"
        //Vue.$log.debug('descriptionInput ' + this.betgamendescription + ' --- ' + target.value)
      },
       */
      updateBetgame (data) {
        this.$store.dispatch('patchBetgame', data)
        .then(() => {
          Vue.$log.debug('watch betgamendescription ok')
        })
        .catch(function (error) {
          Vue.$log.debug('watch betgamendescription catch ' + error)
        })
        .finally(function () {
          Vue.$log.debug('watch betgamendescription finally ')
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  
  .input-container {
    margin: 16px auto 0;
  }
  
  .betgame_edit {
    word-wrap: break-word;
    max-width: 576px;
    
    @media screen and (min-width: 768px) {
      margin: 36px auto 0;
    }
  }
  
  .router_link {
    text-decoration: none;
  }

  .table-view-cell {
    img {
      width: 24px;
      height: 24px;
      border-radius: 3px;
      overflow: hidden;
      object-fit: cover;
    }
  }
  
  .betgame_points_warning {
    color: red;
  }

  .solution_missing {
    color: red;
  }
  
</style>

<template>

  <div class="dashboard">

    <!-- Ad: Bwin Bannner -->
    <!-- <BwinBanner></BwinBanner> -->

    <ValidationPending v-if="!enterCodeClicked && this.$store.state.user && this.$store.state.user.status === 'validation_pending'"></ValidationPending>
    
    <!-- Profile Card -->
    <ProfileCard></ProfileCard>

    <!-- Optional: Nur sichtbar, wenn User Spieltagssieger des letzten abgelaufenen Spieltags ist -->
    <MatchdayWinner v-if="this.isBetgame() && nextMatch && lastMatchdayWon && !matchRunning && !hasTodaysMatches"></MatchdayWinner>

    <MatchdayPreview v-if="this.$store.state.betgame && this.$store.state.betgame.competition && 
                this.$store.state.betgame.competition.type === 'TOURNAMENT' && !this.allMatchesFinished() && !matchRunning 
                && !todaysMatchdayStarted && !nextMatch"></MatchdayPreview>
    
    <!-- Nächster Spieltag / Countdown -->
    <!-- Optional: Nur sichtbar, wenn kein aktiver Spieltag -->
    <MatchdaySlider v-if="(!hasTodaysMatches || matchdayCountdown) && !this.allMatchesFinished() && !todaysMatchdayStarted && nextMatch"></MatchdaySlider>

    <!-- Aktiver Spieltag -->
    <DashboardLiveStatisticsOneMatch v-if="this.selectedDashboardLiveView === 1" :match="singleMatch"></DashboardLiveStatisticsOneMatch>
    <DashboardLiveStatisticsTwoMatches v-else-if="this.selectedDashboardLiveView === 2" :matches="twoMatches"></DashboardLiveStatisticsTwoMatches>
    <DashboardLiveStatisticsMultipleMatches v-else-if="this.selectedDashboardLiveView === 3" :runningMatchday="this.runningMatchday"></DashboardLiveStatisticsMultipleMatches>

    <!-- Optional: Nur sichtbar, wenn Tipprunde/Saison vorbei -->
    <BetgameFinished v-if="this.allMatchesFinished() && !this.specialQuestionMissingSolutions()"></BetgameFinished>
    
    <!-- Gesamtwertung -->
    <DashboardRanking :rankings="dashboardRankings"></DashboardRanking>

    <!-- Formkurve -->
    <DashboardTrendbox v-if="this.isBetgame() && !this.isTournament()"></DashboardTrendbox>

    <!-- Beschreibung -->
    <BetgameDescription></BetgameDescription>

  </div>
</template>

<script>
import NavigationBar from './../components/NavigationBar'
import BwinBanner from './../components/BwinBanner'
import ProfileCard from './../components/ProfileCard'
import MatchdaySlider from './../components/MatchdaySlider'
import DashboardRanking from './../components/DashboardRanking'
import DashboardTrendbox from './../components/DashboardTrendbox'
import BetgameDescription from './../components/BetgameDescription'
import moment from 'moment/moment'
import DashboardLiveStatisticsOneMatch from '@/components/DashboardLiveStatisticsOneMatch.vue'
import DashboardLiveStatisticsTwoMatches from '@/components/DashboardLiveStatisticsTwoMatches.vue'
import DashboardLiveStatisticsMultipleMatches from '@/components/DashboardLiveStatisticsMultipleMatches.vue'
import BetgameFinished from '@/components/BetgameFinished.vue'
import MatchdayWinner from '@/components/MatchdayWinner.vue'
import Vue from 'vue'
import jwt_decode from 'jwt-decode'
import { TYPE_TEAMS_ID } from '@/main'
import ValidationPending from '@/views/ValidationPending.vue'
import MatchdayPreview from '@/components/MatchdayPreview.vue'

export default {
  name: 'DashboardNew',
  components: { MatchdayPreview, ValidationPending, MatchdayWinner, BetgameFinished, DashboardLiveStatisticsOneMatch, DashboardLiveStatisticsTwoMatches, DashboardLiveStatisticsMultipleMatches, NavigationBar, BwinBanner, ProfileCard, MatchdaySlider, DashboardRanking, DashboardTrendbox, BetgameDescription },
  data () {
    return {
      pushParameter: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.previousRoute = from.name
    })
  },
  beforeCreate () {
    Vue.$log.debug('DashboardNew beforeCreate')
    this.$router.push('/feed/dashboard').catch(err => {})
  },
  created () {
    Vue.$log.debug('DashboardNew created ' + this.$route.query.push)

    this.pushParameter = this.$route.query.push
    
    if(this.$store.state.betgame && this.$store.state.betgame.fk_user && this.$store.state.user && this.$store.state.betgame.fk_user === this.$store.state.user.id) {
      const d = new Date();
      d.setTime(d.getTime() + (1*24*60*60*1000));
      let expires = "expires="+ d.toUTCString();
      document.cookie = "SPMAUTH=1;" + expires + ";path=/";
    } else {
      document.cookie = 'SPMAUTH=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
    }
    
    if(this.$store.state.betgameId && this.$store.state.betgame && !this.$store.state.betgame.season) {
      this.$store.dispatch('fetchBetgame', this.$store.state.betgameId)
    }
    
    if(this.$store.state.betgame && this.$store.state.betgame.type === TYPE_TEAMS_ID && (!this.$store.state.betgameTeams || this.$store.state.betgameTeams.length === 0)) {
      this.$store.dispatch('fetchBetgameTeams', this.$store.state.betgameId).then(() => {
        this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
        })
      })
    }

    const lastFetchSchedule = this.getBGValue('spmlfs')
    const duration2 = (new Date() - new Date(lastFetchSchedule)) / 1000
    const cachingDuration = Math.min(this.$store.getters.getCachingDuration, 60)
    if ((lastFetchSchedule !== null && duration2 > cachingDuration) || this.$store.state.matches == null || this.$store.state.matches.length === 0) {
      this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
      })
    }
    
    if (this.$store.state.teams.length === 0 && this.$store.state.betgameId) {
      this.$store.dispatch('fetchTeams', this.$store.state.betgameId)
    }

    if (this.$store.state.betgame && this.$store.state.betgame.competition &&
        this.$store.state.betgame.competition.type === 'TOURNAMENT') {
      if (this.$store.state.tournamentRounds.length === 0 || this.$store.state.tournamentMatches.length === 0) {
        if (this.$store.state.betgame.season) {
          this.$store.dispatch('fetchTournamentRounds', {
            fk_competition: this.$store.state.betgame.competition.id,
            fk_season: this.$store.state.betgame.season.id
          }).then(() => {
          })
        }
      }
    }
    
    if(this.$store.state.user && (this.$store.state.missing_bets === undefined || this.$store.state.missing_bets === null || this.$store.state.missing_bets.length === 0)) {
      this.$store.dispatch('fetchUserBetgames', this.$store.state.user.id)
    }
    if(!this.isBattle()) {
      if (this.$store.state.betgame && this.$store.state.betgame.season) {
        this.$store.dispatch('fetchQuestions', []).then(function (response) {
        })
      }
      if (!this.$store.state.answers || this.$store.state.updateAnswerCount) {
        this.$store.dispatch('updateAnswerCount', false).then(function (response) {
        })
        this.$store.dispatch('fetchUserAnswers', []).then(function (response) {
        })
      }
    }
    if(!this.nextMatch) {
      if (this.isBattle() || this.isTeamsBetgame()) {
        this.$store.dispatch('updateShowLoadingOverlayStatus', 1)
        if(this.$store.state.competitions.length === 0) {
          this.$store.dispatch('fetchCompetitions', '')
        }
        this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgame.id, this.$store.state.leagueId, 0]).then(() => {
        })
      } else {
        this.$store.dispatch('updateShowLoadingOverlayStatus', 1)
        this.$store.dispatch('fetchBetgameRankings', { fk_betgame: this.$store.state.betgame.id }).then(function (response) {
        })
      }
    }
  },
  mounted () {
    Vue.$log.debug('DashboardNew mounted')
    
    if(this.$store.state.betgameId && parseInt(this.$store.state.betgameId) > 0) {
      Vue.$log.debug('dashboard mounted fetchBetgameUsers ' + this.$store.state.betgameId)
      this.$store.dispatch('fetchBetgameUsers', this.$store.state.betgameId)
    }

    if(this.$store.state.user && this.$store.state.betgameId && (!this.$store.state.bets || this.$store.state.bets.length === 0)) {
      this.$store.dispatch('fetchBets', this.$store.state.user.id).then(() => {
      })
      if(this.showBetgamePointsHint || this.showBetgamePointsHintUser) {
        this.$store.dispatch('fetchBetgame', this.$store.state.betgameId)
      }
    }
    if(this.$store.state.betgameId && parseInt(this.$store.state.betgameId) > 0) {
      if (this.$store.state.leagueId && !this.isTournament()) {
        this.$store.dispatch('fetchMatchdayWins', this.$store.state.betgameId).then(() => {
        })
      } else {
        if(!this.$store.state.leagueId || !this.$store.state.betgame.competition || !this.$store.state.betgame.season) {
          this.$store.dispatch('fetchBetgameLeague', this.$store.state.betgameId).then(() => {
          })
        } else {
        }
      }
      
      if(this.isBattle()) {
        const lastFetchLiveRanking = this.getBGValue('spmlflr')
        const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
        if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
            this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
          this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRankingBattle', [this.$store.state.betgameId, this.matchday]).then(() => {
              this.setBGValue('spmlflr', new Date())
            })
          })

        }
      } else if(this.isTeamsBetgame()) {
        const lastFetchLiveRanking = this.getBGValue('spmlflr')
        const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
        if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
            this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
          this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRankingTeam', [this.$store.state.betgameId, this.matchday]).then(() => {
              this.setBGValue('spmlflr', new Date())
            })
          })

        }
      } else {
        const lastFetchLiveRanking = this.getBGValue('spmlflr')
        const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
        if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
            this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
          if (this.$store.state.leagueId && this.$store.state.betgameId) {
            this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
            })
          }
        }
      }

      if(this.previousRoute && this.previousRoute !== 'dashboard' && this.previousRoute !== 'mybetgames') {
        if(this.showSPMReviewPopup()) {
          this.requestReview()
        }
      }
    } else {
      if(this.$store.getters.userBetgamesCount > 0) {
        Vue.$log.debug('dashboard mounted !this.$store.state.betgameId /mybetgames')
        this.$router.push('/mybetgames')
      } else {
        Vue.$log.debug('dashboard mounted !this.$store.state.betgameId /searchbetgame')
        this.$router.push('/searchbetgame')
      }
    }
    
    if(this.$store.state.betgame && this.$store.state.user) {
      const uid = '' + this.$store.state.user.id
      const uem = '' + this.$store.state.user.email.charAt(0) + this.reverse(uid)
      const temp = this.reverse(String(this.$store.state.user.registration_date)).replaceAll('T', this.reverse(uem))
      .replaceAll(':', '').replaceAll('-', uem).concat(uid)
      if(!this.$store.state.chat_token || this.$store.state.chat_token === '') {
        if(this.$store.state.user.chatuser && this.$store.state.user.chatuser !== '') {
          if(this.$store.state.user.chatuser.includes('-')) {
            const t = this.$store.state.user.chatuser.split('-')
            if(t.length === 2) {
              this.$store.dispatch('chatLoginUser', {'user': t[0], 'password': t[1]}).then(response => {
                this.$log.debug('dashboard mounted chatLoginUser response: ' + JSON.stringify(response))
                this.$store.dispatch('fetchChatItems', true)
              })
            }
          } else {
            this.$store.dispatch('chatLoginUser', {'user': uid, 'password': temp}).then(response => {
              this.$log.debug('dashboard mounted chatLoginUser response: ' + JSON.stringify(response))
              this.$store.dispatch('fetchChatItems', true)
              this.$store.dispatch('patchUser', {
                chatuser: uid + '-' + temp
              })
            })
          }
        } 
      } else {
        const payload = jwt_decode(JSON.stringify(this.$store.state.chat_token))
        const a = payload.exp * 1000
        const b = moment()
        if(a < b) {
          this.$store.dispatch('chatLoginUser', {'user': uid, 'password': temp}).then(response => {
            this.$log.debug('app mounted chatLoginUser response: ' + JSON.stringify(response))
            this.$store.dispatch('fetchChatItems', true)
          })
        }
        if(!this.$store.state.user.chatuser.includes('-')) {
          this.$store.dispatch('patchUser', {
            chatuser: uid + '-' + temp
          })
        }
      }
    }

    //if(this.$store.state.showLoadingOverlay) {
      setTimeout(() => {
        var element = document.getElementById("pagebody")
        if(element) {
          element.classList.remove("bodybackground")
        }
        this.$store.dispatch('setShowLoadingOverlay', false)
      }, 5000);
    //}
  },
  computed: {
    enterCodeClicked() {
      return this.getBGValue('spmvr')
    },
    lastMatchdayWon() {
      if(this.nextMatch && this.nextMatch.matchday > 1) {
        if (this.matchdayWins.length > 0) {
          return this.matchdayWins.filter(matchdayWin => matchdayWin.matchday === (this.nextMatch.matchday - 1)).length > 0
        }
      }
      return false
    },
    matchdayWins() {
      if(this.$store.state.user) {
        return this.$store.getters.getMatchdayWins(this.$store.state.user.id)
      }
      return []
    },
    matchRunning() {
      return this.$store.getters.getLiveMatch
    },
    nextMatch() {
      if(this.$store.state.matches.length > 0) {
        return this.$store.getters.getClosestFutureMatch
      } else {
        if(this.$store.state.leagueId) {
          this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
        } else {
          if(this.getBGValue('spmbgid') && parseInt(this.getBGValue('spmbgid')) > 0) {
            this.$store.dispatch('fetchBetgameLeague', this.getBGValue('spmbgid'))
          }
        }
      }
    },
    matchdayCountdown() {
      if(this.isBetgame()) {
        if(this.$store.state.runningMatches.length === 0 || !this.checkStatus(this.$store.state.runningMatches)) {
          for(var i=0; i<this.$store.state.todaysMatches.length; i++) {
            const matchid = this.$store.state.todaysMatches[i]
            const match = this.$store.getters.getMatcheById(matchid)
            if((match.livegoalshome !== null && match.livegoalsguest !== null) || (match.goalshome !== null && match.goalsguest !== null)) {
              return false
            }
          }
          return true
        }
      }
      return false
    },
    todaysMatchdayStarted() {
      return this.$store.getters.getTodaysMatchesMatchdayStarted
    },
    hasTodaysMatches() {
      return this.$store.state.todaysMatches.length > 0
    },
    singleMatch() {
      return this.$store.getters.getMatcheById(this.$store.state.todaysMatches[0])
    },
    twoMatches() {
      const m = [this.$store.getters.getMatcheById(this.$store.state.todaysMatches[0]),
        this.$store.getters.getMatcheById(this.$store.state.todaysMatches[1])]
      m.sort(function (a, b) {
        return a.matchdate !== b.matchdate ? Date.parse(a.matchdate) - Date.parse(b.matchdate) : a.id - b.id
      })
      return m
    },
    runningMatchday() {
      const today = this.$store.state.todaysMatches
      if (today.length > 0) {
        const match = this.$store.getters.getMatcheById(today[0])
        return match.matchday
      }
      const t = this.getRunningMatchdays
      return t.length > 0 ? t[0].matchday : 0
    },
    selectedDashboardLiveView() {
      if(this.$store.state.betgame.type === 1) {
        if(this.getRunningMatchdays.length !== 1) {
          //return 0
        }
        if(this.$store.state.leagueId === 1 || this.$store.state.leagueId === 2 || this.$store.state.leagueId === 998) {
          var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
          if(!timeZone) {
            timeZone = 'Europe/Berlin'
          }
          const nowww = moment.tz(timeZone)
          const t = this.calcMatchdayStartEnd
          for(var i=1; i<t.length; i++) {
            if(t[i] && t[i].start && t[i].end) {
              const matchdayStart = moment.tz(String(t[i].start), timeZone)
              const matchdayEnd = moment.tz(String(t[i].end), timeZone)
              matchdayEnd.add(1, 'days').endOf('day')
              if(nowww.isBetween(matchdayStart, matchdayEnd)) {
                return 3
              }
            }
          }
        } else {
          if(this.$store.getters.getTodaysMatchesMatchdayStarted) {
            if (this.$store.state.todaysMatches.length > 2) {
              return 3
            } else if (this.$store.state.todaysMatches.length === 2) {
              return 2
            } else if (this.$store.state.todaysMatches.length === 1) {
              return 1
            } else {
              return 0
            }
          } else {
            return 0
          }
        }
      } else if(this.$store.state.betgame.type === 2) {
        if(this.$store.state.todaysMatches.length > 2) {
          return 3
        } else if(this.$store.state.todaysMatches.length === 2) {
          return 2
        } else if(this.$store.state.todaysMatches.length === 1) {
          return 1
        } else {
          return 0
        }
      } else if(this.$store.state.betgame.type === 3) {
        if(this.$store.state.todaysMatches.length > 2) {
          return 3
        } else if(this.$store.state.todaysMatches.length === 2) {
          return 2
        } else if(this.$store.state.todaysMatches.length === 1) {
          return 1
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    getRunningMatchdays() {
      const matchdays = this.calcMatchdayStartEnd
      var result = []
      var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if(!timeZone) {
        timeZone = 'Europe/Berlin'
      }
      const nowww = moment.tz(timeZone)
      for(var i=0; i<matchdays.length; i++) {
        const matchday = matchdays[i]
        if(matchday && matchday.start && matchday.end) {
          const start = this.parseMatchdate(matchday.start)
          const end = this.parseMatchdate(matchday.end)

          const matchdayStart = moment.tz(String(matchday.start), timeZone)
          const matchdayEnd = moment.tz(String(matchday.end), timeZone)
          matchdayEnd.add(1, 'days').endOf('day')


          if(start && end) {
            if(start.isSame(moment(Date.now()), 'day') || end.isSame(moment(Date.now()), 'day') ||
                (start.isBefore(moment(Date.now()), 'day') && end.isAfter(moment(Date.now()), 'day'))) {
              result.push(matchday)
            } else if(nowww.isBetween(matchdayStart, matchdayEnd)) {
              result.push(matchday)
            }
          }
        }
      }
      return result
    },
    calcMatchdayStartEnd() {
      const matchesByMathday = this.groupMatchesByMathday
      var result = []
      for(var matchday = 0; matchday < matchesByMathday.length; matchday++) {
        const matchdayMatches = matchesByMathday[matchday]
        if(matchdayMatches) {
          const sorted = matchdayMatches.sort(function (a, b) {
            return Date.parse(a.matchdate) - Date.parse(b.matchdate)
          })
          const matchdayStart = sorted[0].matchdate
          const matchdayEnd = sorted[sorted.length - 1].matchdate
          const diffInDays = 1 + Math.floor((Date.parse(matchdayEnd) - Date.parse(matchdayStart)) / (1000 * 60 * 60 * 24))
          result[matchday] = {
            start: matchdayStart,
            end: matchdayEnd,
            days: diffInDays,
            matchday: matchday
          }
        }
      }
      return result
    },
    groupMatchesByMathday() {
      var result = []
      for(var i = 0; i < this.$store.state.matches.length; i++) {
        const match = this.$store.state.matches[i]
        if(result[match.matchday] === undefined) {
          result[match.matchday] = []
        }
        result[match.matchday].push(match)
      }
      return result
    },
    dashboardRankings() {
      if(this.hasSeasonStarted()) {
        if (!this.$store.state.userNeighbourRankings) {
          if (!this.$store.state.leagueId && this.getBGValue('spmbgid') && parseInt(this.getBGValue('spmbgid')) > 0) {
            this.$store.dispatch('fetchBetgameLeague', this.getBGValue('spmbgid'))
          }
        }
        return this.$store.getters.getRankingForDashboard
      } else {
        var r = this.$store.state.betgameUsers
        if(!r) {
          return []
        }
        if(r.length > 100) {
          r = r.slice(0, 100)
        }
        var result = []
        for(var i=0; i<r.length; i++) {
          result.push({"position": i+1, "fk_betgame": this.$store.state.betgameId, 
            "fk_competition": 0, "fk_user": r[i].fk_user, "user_name": r[i].nickname, 
            "points_total":0,"bet_points":0,"question_points":0,"points_correction":0,"matchday_wins":0,"exact":0,"goaldiff":0,
            "tendency":0,"no_points":81,"betcount":81,"position_int":1})
        }
        return result
      }
    },
    betgameUsers () {
      const r = this.$store.state.betgameUsers
      if(!r) {
        return []
      }
      if(r.length > 100) {
        return r.slice(0, 100)
      }
      return r
    },
    betgameName() {
      if(this.$store.state.betgame) {
        return this.$store.state.betgame.name
      } else {
        if(this.getBGValue('spmbgid') && parseInt(this.getBGValue('spmbgid')) > 0) {
          this.$store.dispatch('fetchBetgame', this.getBGValue('spmbgid'))
        }
      }
    },
  },
  methods: {
    checkStatus (matches) {
      for (var i = 0; i < matches.length; i++) {
        const match = matches[i]
        if (match.status && (match.status.trim() === 'annulliert' || match.status.trim() === 'abgesagt'
            || match.status.trim() === 'verlegt' || match.status.trim() === 'Wertung')) {
          return true
        }
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
  .dashboard {
    width: 100%;
  }
  .dashboard-container {
    flex: 100%;
  }
  small {
    width: 100%;
    text-align: center;
    margin-bottom: -8px;

    @media screen and (min-width: 768px) {
      margin-bottom: -12px;
    }
  }
</style>

<template>
  <div class="audio" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
  
    <keep-alive>
      <NavigationBar>
        <template v-slot:center>
          Audio
        </template>
        <template v-slot:right>
        </template>
      </NavigationBar>
    </keep-alive>

    <div style="max-width: 200px;">
      
      <div>Audio</div>

      <audio id="audio" controls>
        <source src="../assets/audio/sample-0.mp3" type="audio/ogg">
      </audio>
      

    </div>

  </div>
</template>



<script>
import NavigationBar from './../components/NavigationBar'

export default {
  components: { NavigationBar },
  created() {

  },
  mounted () {

  },
  methods: {
  
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="profileChangeRakingsPageSize" id="profileChangeRakingsPageSize">

    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('menu.matchday_ranking') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>
    
    <RankingsPerPage style="margin-top: 30px;"></RankingsPerPage>
    
  </div>
</template>

<script>

import NavigationBar from './../components/NavigationBar'
import RankingsPerPage from '@/components/settings/RankingsPerPage.vue'

export default {
  name: 'ProfileChangeRakingsPageSize',
  components: { RankingsPerPage, NavigationBar },
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    goBack () {
      this.$router.push('/profile')
    }
  }
}
</script>

<style lang="scss" scoped>
.profileChangeRakingsPageSize {

  form {
    padding-top: 16px;
  }

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
    margin-bottom: 26px;
  }
}
</style>

<template>
  <div class="input-container" style="margin-top: 16px;">
    <div>
      <div class="input-header">
        {{ $t('betgame_settings.generate_bets_title') }}
      </div>
      <div class="input-infotext">
        {{ $t('betgame_settings.generate_bets_text') }}
      </div>
      <div class="input-radio">
        <input v-model="generatebets" v-on:change="generateBetsChanged(true)" type="radio" value="true" id="yes"
               name="generatebets"/>
        <label for="yes">{{ $t('yes') }}</label>
      </div>
      <div class="input-radio">
        <input v-model="generatebets" v-on:change="generateBetsChanged(false)" type="radio" value="false" id="no"
               name="generatebets"/>
        <label for="no">{{ $t('no') }}</label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GenerateBets',
  components: {},
  data () {
    return {
      generatebets: this.getBGValue('spmgenbets') ? this.getBGValue('spmgenbets') : 'true'
    }
  },
  beforeCreate () {
  },
  created () {
  },
  mounted () {
  },
  computed: {},
  methods: {
    generateBetsChanged (value) {
      this.setBGValue('spmgenbets', '' + value)
    },
  }
}
</script>

<style lang="scss">

</style>


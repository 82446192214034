<template>
  <div class="matchdaypreview">
    <div class="dashboard-text">
      <h1>{{ $t('dashboard.tournament_preview_title') }}</h1>
      <p>{{ $t('dashboard.tournament_preview_text') }}</p>
      <router-link :to="{name: 'rankings'}">
        <button>{{ $t('dashboard.button_view_rankings_total') }}</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MatchdayPreview',
  components: {},
  data () {
    return {
      pushParameter: ''
    }
  },
  beforeCreate () {
  },
  created () {
  },
  mounted () {
  },
  computed: {

  },
  methods: {}
}
</script>

<style lang="scss">
.matchdaypreview {
  background: $colorWhite;
  box-shadow: 0 0 1px 0 rgba($colorGreyDark, .08), 0px 4px 16px rgba($colorGreyDark, 0.08);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 24px;
}
</style>
